import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeIcon from "./../../assets/images/icons/leftbar/home.png";
import InplayIcon from "./../../assets/images/icons/leftbar/inplay.png";
import CricketIcon from "./../../assets/images/icons/leftbar/cricket.png";
import SoccerIcon from "./../../assets/images/icons/leftbar/soccer.png";
import TennisIcon from "./../../assets/images/icons/leftbar/tennis.png";
import horseracingIcon from "./../../assets/images/games-icon/horseracing-icon.png";
import SnookerIcon from "./../../assets/images/games-icon/snooker-icon.png";
import GreyhoundIcon from "./../../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "./../../assets/images/games-icon/politics-icon.png";
import EzugiIcon from "./../../assets/images/icons/leftbar/ezugi.png";
import EvolutionIcon from "./../../assets/images/icons/leftbar/evolution.png";
import VivoIcon from "./../../assets/images/icons/leftbar/vivo.png";
import SupernowaIcon from "./../../assets/images/icons/leftbar/supernowa.png";
import xpgIcon from "./../../assets/images/icons/leftbar/xpg.png";
import WorldcasinoIcon from "./../../assets/images/icons/leftbar/worldcasino.png";
import SlotsIcon from "./../../assets/images/icons/leftbar/slots.png";
import Whatsapp from "./../../assets/images/Whatsapp.svg";
import Telegram from "./../../assets/images/telegram.svg";
import Instagram from "./../../assets/images/Instagram.svg";
import Facebook from "./../../assets/images/facebook-512.svg";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  return (
    <>
      <div className="leftbarSec d-none d-md-block">
        <ul>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sports");
              }}
            >
              <img src={HomeIcon} alt="home" />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sportsbook/Inplay", {
                  state: { selectedTab: "1" },
                });
              }}
            >
              <img src={InplayIcon} alt="inplay" />
              <span>In-play</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sportsbook/Cricket", {
                  state: { selectedTab: "2" },
                });
              }}
            >
              <img src={CricketIcon} alt="cricket" />
              <span>Cricket</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sportsbook/Soccer", {
                  state: { selectedTab: "3" },
                });
              }}
            >
              <img src={SoccerIcon} alt="soccer" />
              <span>Soccer</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sportsbook/Tennis", {
                  state: { selectedTab: "4" },
                });
              }}
            >
              <img src={TennisIcon} alt="tennis" />
              <span>Tennis</span>
            </a>
          </li>
        </ul>

        <ul>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/casino/ezugi");
              }}
            >
              <img src={EzugiIcon} alt="ezugi" />
              <span>Ezugi</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/casino/evolution");
              }}
            >
              <img src={EvolutionIcon} alt="evolution" />
              <span>Evolution</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/casino/vivo");
              }}
            >
              <img src={VivoIcon} alt="vivo" />
              <span>Vivo</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/casino/supernowa");
              }}
            >
              <img src={SupernowaIcon} alt="supernowa" />
              <span>Supernowa</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/casino/xpg");
              }}
            >
              <img src={xpgIcon} alt="xpg" />
              <span>XPG</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/casino/worldcasino");
              }}
            >
              <img src={WorldcasinoIcon} alt="Worldcasino" />
              <span>Worldcasino</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/slots");
              }}
            >
              <img src={SlotsIcon} alt="slots" />
              <span>Slots</span>
            </a>
          </li>
        </ul>

        <ul>
          <li>
            <a
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "javascript:void(0)"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              <img src={Whatsapp} alt="whatsapp" />
              <span>WhatsAap</span>
            </a>
          </li>
          <li>
            <a
              href={
                appDetails?.TELEGRAM != ""
                  ? appDetails?.TELEGRAM
                  : "javascript:void(0)"
              }
              target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
            >
              <img src={Telegram} alt="telegram" />
              <span>Telegram</span>
            </a>
          </li>
          <li>
            <a
              href={
                appDetails?.INSTATGRAM != ""
                  ? appDetails?.INSTATGRAM
                  : "javascript:void(0)"
              }
              target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
            >
              <img src={Instagram} alt="instagram" />
              <span>Instagram</span>
            </a>
          </li>
          <li>
            <a
              href={
                appDetails?.FACEBOOK != ""
                  ? appDetails?.FACEBOOK
                  : "javascript:void(0)"
              }
              target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
            >
              <img src={Facebook} alt="facebook}" />
              <span>Facebook</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
