import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { Footer } from "@mantine/core";
import CasinoCard from "../../../components/CasinoCard";
import { casinoTabs } from "../../../../lib/data";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";

const Spribe = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main mt-4">
        <div className=" container">
          <div className="d-flex justify-content-between HeadingSection">
            <h2 className="sectionTitle mb-0">Spribe</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="SpribeGames">
            {Array.isArray(Object.values(casinoTabs?.spribe.slide1)) &&
              Object.values(casinoTabs?.spribe.slide1)
                .slice(0, 7)
                .map((game, idx) => (
                  <>
                    {console.log("game", game)}
                    <CasinoCard key={"casinogame" + idx} data={game} />
                  </>
                ))}
          </div>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Spribe;
