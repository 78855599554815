import React from "react";
import { useNavigate } from "react-router-dom";

const ImageCard = ({ item, isAuth, favourite }) => {
  const navigate = useNavigate();
  const handleRedirection = (item) => {
    if (item?.providerName && item?.providerName === "Pragmatic Play") {
      // window.open(item.redirectUrl, "_blank");
      navigate(item.redirectUrl, {
        state: {
          requiredUserAction: true,
        },
      });
    } else {
      window.location.href = item.redirectUrl;
    }
  };
  return (
    <li className="game-section">
      <a onClick={() => (isAuth ? handleRedirection(item) : "/sign-in")}>
        <img src={item.imgUrl} className="main-img" />
        <div className="d-flex game-name">
          <div>
            <p>{item.name}</p>
            <p className="providernames">{item.providerName}</p>
          </div>
        </div>
      </a>
    </li>
  );
};
export default ImageCard;
