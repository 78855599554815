import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import BaccaratB from "../../../../assets/images/baccarat/Baccarat-B.jpg";
import BaccaratC from "../../../../assets/images/baccarat/Baccarat-C.jpg";
import GoldenWealthBaccarat from "../../../../assets/images/baccarat/Golden-Wealth-Baccarat.jpg";
import LightningBaccarat from "../../../../assets/images/baccarat/Lightning-Baccarat.jpg";
import NoCommBaccarat1 from "../../../../assets/images/baccarat/No-Commission-Baccarat.jpg";
import NoCommSpeedBaccaratA from "../../../../assets/images/baccarat/No-Commission-Speed-Baccarat-A.jpg";
import NoCommSpeedBaccaratB from "../../../../assets/images/baccarat/No-Commission-Speed-Baccarat-B.jpg";
import SalonPriveBaccarat from "../../../../assets/images/baccarat/Salon-Prive-Baccarat-C.jpg";
import SpeedBaccaratA from "../../../../assets/images/baccarat/Speed-Baccarat-A.jpg";
import SpeedBaccaratB from "../../../../assets/images/baccarat/Speed-Baccarat-B.jpg";
import SpeedBaccaratC from "../../../../assets/images/baccarat/Speed-Baccarat-C.jpg";
import SpeedBaccaratE from "../../../../assets/images/baccarat/Speed-Baccarat-E.jpg";

import Baccarat1 from "../../../../assets/images/baccarat/Baccarat.jpg";
import FortuneBaccarat from "../../../../assets/images/baccarat/Fortune-Baccarat.jpg";
import SpeedCricketBaccarat from "../../../../assets/images/baccarat/Speed-Cricket-Baccarat.jpg";
import VIPFortuneBaccarat from "../../../../assets/images/baccarat/VIP-Fortune-Baccarat.jpg";
import NoCommBaccarat from "../../../../assets/images/baccarat/No-Commission-Baccarat.jpg";
import FiestaBaccarat from "../../../../assets/images/baccarat/Fiesta-Baccarat.jpg";
import CasinoMarinaBaccarat1 from "../../../../assets/images/baccarat/Casino-Marina-Baccarat-1.jpg";
import CasinoMarinaBaccarat2 from "../../../../assets/images/baccarat/Casino-Marina-Baccarat-2.jpg";
import CasinoMarinaBaccarat3 from "../../../../assets/images/baccarat/Casino-Marina-Baccarat-3.jpg";
import CasinoMarinaBaccarat4 from "../../../../assets/images/baccarat/Casino-Marina-Baccarat-4.jpg";

import BaccaratSupernowa from "../../../../assets/images/baccarat/BaccaratSupernowa.jpg";
import RNGBaccarat2020Supernowa from "../../../../assets/images/baccarat/RNG-Baccarat-2020.jpg";
import RNGBaccaratSupernowa from "../../../../assets/images/baccarat/RNG-Baccarat.jpg";

const SecondGamePage = () => {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main">
        <div className="container">
          <div className="game-page">
            <h2 class="sectionTitle">Baccarat</h2>
            <div className="provider-game-name">
              <h3>Ezugi</h3>
            </div>
            <div className="provider-game-section">
              <ul>
                <li>
                  <a href="/casino/ezugi/Baccarat">
                    <img src={Baccarat1} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/FortuneBaccarat">
                    <img src={FortuneBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/SpeedCricketBaccarat">
                    <img src={SpeedCricketBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/FortuneBaccarat">
                    <img src={VIPFortuneBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/NoCommissionBaccarat">
                    <img src={NoCommBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/FiestaBaccarat">
                    <img src={FiestaBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/CasinoMariniaBaccarat1">
                    <img src={CasinoMarinaBaccarat1} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/CasinoMariniaBaccarat2">
                    <img src={CasinoMarinaBaccarat2} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/CasinoMariniaBaccarat3">
                    <img src={CasinoMarinaBaccarat3} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/CasinoMariniaBaccarat4">
                    <img src={CasinoMarinaBaccarat4} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="provider-game-name">
              <h3>Evolution</h3>
            </div>
            <div className="provider-game-section">
              <ul>
                <li>
                  <a href="/casino/evolution/BaccaratB">
                    <img src={BaccaratB} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/BaccaratC">
                    <img src={BaccaratC} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/GoldenWealthBaccarat">
                    <img src={GoldenWealthBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/LightningBaccarat">
                    <img src={LightningBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/NoCommBaccarat">
                    <img src={NoCommBaccarat1} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/NoCommSpeedBaccarat">
                    <img src={NoCommSpeedBaccaratA} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/NoCommSpeedBaccarat">
                    <img src={NoCommSpeedBaccaratB} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SalonPriveBaccarat">
                    <img src={SalonPriveBaccarat} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SpeedBaccaratA">
                    <img src={SpeedBaccaratA} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SpeedBaccaratB">
                    <img src={SpeedBaccaratB} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SpeedBaccaratC">
                    <img src={SpeedBaccaratC} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SpeedBaccaratE">
                    <img src={SpeedBaccaratE} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="provider-game-name">
              <h3>Rudrabets Games</h3>
            </div>
            <div className="provider-game-section">
              <ul>
                <li>
                  <a href="casino/ezugi/BaccaratSupernowa">
                    <img src={BaccaratSupernowa} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/RNGBaccaratSupernowa">
                    <img src={RNGBaccaratSupernowa} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/RNGBaccarat2020Supernowa">
                    <img src={RNGBaccarat2020Supernowa} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default SecondGamePage;
