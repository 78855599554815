export const auraGames = {
  improvedExperience: {
    slide1: {
      DreamCatcher: {
        code: "98567",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Dream Catcher",
        name: "Dream Catcher",
        providerName: "aura",
        imgUrl: "./images/aura/DreamCatcher.jpg",
        redirectUrl: "/casino/aura-dream-catcher",
      },
      TeenpattiT20: {
        code: "56768",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Teenpatti T20",
        name: "Teenpatti T20",
        providerName: "aura",
        imgUrl: "./images/aura/TeenpattiT20.jpg",
        redirectUrl: "/casino/aura-teenpatti-t20",
      },
      DragonTiger: {
        code: "98790",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Dragon Tiger",
        name: "Dragon Tiger",
        providerName: "aura",
        imgUrl: "./images/aura/DragonTiger.jpg",
        redirectUrl: "/casino/aura-dragon-tiger",
      },
      UpDown7: {
        code: "98789",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "7 Up Down",
        name: "7 Up Down",
        providerName: "aura",
        imgUrl: "./images/aura/7UpDown.jpg",
        redirectUrl: "/casino/aura-7-up-down",
      },
      HiLow: {
        code: "56968\n",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Hi Low",
        name: "Hi Low",
        providerName: "aura",
        imgUrl: "./images/aura/HiLow.jpg",
        redirectUrl: "/casino/aura-hi-low",
      },
      CardTeenpatti2: {
        code: "67660",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "2 Card Teenpatti",
        name: "2 Card Teenpatti",
        providerName: "aura",
        imgUrl: "./images/aura/2CardsTeenpatti.jpg",
        redirectUrl: "/casino/aura-2-card-teenpatti",
      },
      CasinoWar: {
        code: "67580",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Casino War",
        name: "Casino War",
        providerName: "aura",
        imgUrl: "./images/aura/CasinoWar.jpg",
        redirectUrl: "/casino/aura-casino-war",
      },
      MuflisTeenpatti: {
        code: "67600",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Muflis Teenpatti",
        name: "Muflis Teenpatti",
        providerName: "aura",
        imgUrl: "./images/aura/MuflisTeenpatti.jpg",
        redirectUrl: "/casino/aura-muflis-teenpatti",
      },
      AndarBahar: {
        code: "87564",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Andar Bahar",
        name: "Andar Bahar",
        providerName: "aura",
        imgUrl: "./images/aura/AndarBahar.jpg",
        redirectUrl: "/casino/aura-andar-bahar",
      },
      QueenRace: {
        code: "67620",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Queen Race",
        name: "Queen Race",
        providerName: "aura",
        imgUrl: "./images/aura/QueenRace.jpg",
        redirectUrl: "/casino/aura-queen-race",
      },
      Poker2020: {
        code: "67567",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Poker 20-20",
        name: "Poker 20-20",
        providerName: "aura",
        imgUrl: "./images/aura/Poker20-20.jpg",
        redirectUrl: "/casino/aura-poker-20-20",
      },
      BollywoodCasino: {
        code: "67570",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Bollywood Casino",
        name: "Bollywood Casino",
        providerName: "aura",
        imgUrl: "./images/aura/BollywoodCasino.jpg",
        redirectUrl: "/casino/aura-bollywood-casino",
      },
    },
    slide2: {
      Teenpatti1Day: {
        code: "56767",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Teenpatti 1 Day",
        name: "Teenpatti 1 Day",
        providerName: "aura",
        imgUrl: "./images/aura/TeenpattiOneDay.jpg",
        redirectUrl: "/casino/aura-teenpatti-1-Day",
      },
      Roulette: {
        code: "98788",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Roulette",
        name: "Roulette",
        providerName: "aura",
        imgUrl: "./images/aura/Roulette.jpg",
        redirectUrl: "/casino/aura-roulette",
      },
      AmarAkbarAnthony: {
        code: "98791",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Amar Akbar Anthony",
        name: "Amar Akbar Anthony",
        providerName: "aura",
        imgUrl: "./images/aura/AmarAkbarAnthony.jpg",
        redirectUrl: "/casino/aura-amar-akbar-anthony",
      },
      TeenpattiTest: {
        code: "67630",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Teenpatti Test",
        name: "Teenpatti Test",
        providerName: "aura",
        imgUrl: "./images/aura/TeenpattiTest.jpg",
        redirectUrl: "/casino/aura-teenpatti-test",
      },
      Sicbo: {
        code: "98566",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Sicbo",
        name: "Sicbo",
        providerName: "aura",
        imgUrl: "./images/aura/Sicbo.jpg",
        redirectUrl: "/casino/aura-sicbo",
      },
    },
    name: "Improved Experience",
    key: "improvedExperience",
  },

  cardGames: {
    slide1: {
      Poker: {
        code: "67564",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Poker",
        name: "Poker",
        providerName: "aura",
        imgUrl: "./images/aura/Poker.jpg",
        redirectUrl: "/casino/aura-poker",
      },
      SuperOver: {
        code: "67720",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Super Over",
        name: "Super Over",
        providerName: "aura",
        imgUrl: "./images/aura/SuperOver.jpg",
        redirectUrl: "/casino/aura-sper-over",
      },
      Matka: {
        code: "92037",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Matka",
        name: "Matka",
        providerName: "aura",
        imgUrl: "./images/aura/Matka(Virtual).jpg",
        redirectUrl: "/casino/aura-matka",
      },
      Baccarat: {
        code: "92038",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Baccarat",
        name: "Baccarat",
        providerName: "aura",
        imgUrl: "./images/aura/Baccarat.jpg",
        redirectUrl: "/casino/aura-baccarat",
      },
      Trio: {
        code: "67610",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Trio",
        name: "Trio",
        providerName: "aura",
        imgUrl: "./images/aura/Trio.jpg",
        redirectUrl: "/casino/aura-trio",
      },
      CardsJudgement3: {
        code: "67670",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "3 Cards Judgement",
        name: "3 Cards Judgement",
        providerName: "aura",
        imgUrl: "./images/aura/3CardsJudgement.jpg",
        redirectUrl: "/casino/aura-3-cards-judgement",
      },
      Race2020: {
        code: "90100",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Race 20-20",
        name: "Race 20-20",
        providerName: "aura",
        imgUrl: "./images/aura/Race20-20.jpg",
        redirectUrl: "/casino/aura-race-20-20",
      },
      CardBaccarat29: {
        code: "67690",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "29 Card Baccarat",
        name: "29 Card Baccarat",
        providerName: "aura",
        imgUrl: "./images/aura/29CardBaccarat.jpg",
        redirectUrl: "/casino/aura-29-card-baccarat",
      },
      TeenpattiOpen: {
        code: "67640",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Teenpatti Open",
        name: "Teenpatti Open",
        providerName: "aura",
        imgUrl: "./images/aura/TeenpattiOpen.jpg",
        redirectUrl: "/casino/aura-teenpatti-open",
      },
      Raceto17: {
        code: "67710",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Race to 17",
        name: "Race to 17",
        providerName: "aura",
        imgUrl: "./images/aura/Raceto17.jpg",
        redirectUrl: "/casino/aura-race-to-17",
      },
      Sixplayerpoker: {
        code: "67565",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Six player poker",
        name: "Six player poker",
        providerName: "aura",
        imgUrl: "./images/aura/Sixplayerpoker.jpg",
        redirectUrl: "/casino/aura-six-player-poker",
      },
      CasinoMeter: {
        code: "67575",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Casino Meter",
        name: "Casino Meter",
        providerName: "aura",
        imgUrl: "./images/aura/CasinoMeter.jpg",
        redirectUrl: "/casino/aura-casino-meter",
      },
      TheTrap: {
        code: "67680",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "The Trap",
        name: "The Trap",
        providerName: "aura",
        imgUrl: "./images/aura/TheTrap.jpg",
        redirectUrl: "/casino/aura-the-trap",
      },
    },
    name: "Card Games",
    key: "cardGames",
  },

  virtualCardGames: {
    slide1: {
      upNDown7Virtual: {
        code: "98793",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "7 up & Down (Virtual)",
        name: "7 up & Down (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/7up&Down(Virtual).jpg",
        redirectUrl: "/casino/aura-7-up-&-down-virtual",
      },
      DragonTigerVirtual: {
        code: "98794",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Dragon Tiger (Virtual)",
        name: "Dragon Tiger (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/DragonTiger(Virtual).jpg",
        redirectUrl: "/casino/aura-dragon-tiger-virtual",
      },
      TeenpattiT20Virtual: {
        code: "56769",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Teenpatti T20 (Virtual)",
        name: "Teenpatti T20 (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/TeenpattiT20(Virtual).jpg",
        redirectUrl: "/casino/aura-teenpatti-t20-virtual",
      },
      HiLowVirtual: {
        code: "56969",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Hi Low (Virtual)",
        name: "Hi Low (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/HiLow(Virtual).jpg",
        redirectUrl: "/casino/aura-hi-low-virtual",
      },
      AmarAkbarAnthonyVirtual: {
        code: "98795",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Amar Akbar Anthony (Virtual)",
        name: "Amar Akbar Anthony (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/AmarAkbarAnthony(Virtual).jpg",
        redirectUrl: "/casino/aura-amar-akbar-anthony-virtual",
      },
      RouletteVirtual: {
        code: "98792",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Roulette (Virtual)",
        name: "Roulette (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/Roulette(Virtual).jpg",
        redirectUrl: "/casino/aura-roulette-virtual",
      },
      AndarBaharVirtual: {
        code: "87565",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Andar Bahar (Virtual)",
        name: "Andar Bahar (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/AndarBahar(Virtual).jpg",
        redirectUrl: "/casino/aura-andar-bahar-virtual",
      },
      cardscasinoVirtual32: {
        code: "56966",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "32 cards casino (Virtual)",
        name: "32 cards casino (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/32cardscasino(Virtual).jpg",
        redirectUrl: "/casino/aura-32-cards-casino-virtual",
      },
      MatkaVirtual: {
        code: "92036",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Matka (Virtual)",
        name: "Matka (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/Matka(Virtual).jpg",
        redirectUrl: "/casino/aura-matka-virtual",
      },
      TeenpattiOneDayVirtual: {
        code: "56766",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Teenpatti One-Day (Virtual)",
        name: "Teenpatti One-Day (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/TeenpattiOneDay(Virtual).jpg",
        redirectUrl: "/casino/aura-teenpatti-one-day-virtual",
      },
      PokerVirtual: {
        code: "67563",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Poker  (Virtual)",
        name: "Poker  (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/Poker(Virtual).jpg",
        redirectUrl: "/casino/aura-poker-virtual",
      },
      SixplayerpokerVirtual: {
        code: "67566",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Six player poker (Virtual)",
        name: "Six player poker (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/Sixplayerpoker(Virtual).jpg",
        redirectUrl: "/casino/aura-six-player-poker-virtual",
      },
    },
    slide2: {
      Race2020Virtual: {
        code: "90101",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Race 20-20 (Virtual)",
        name: "Race 20-20 (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/Race20-20(Virtual).jpg",
        redirectUrl: "/casino/aura-Race-20-20-virtual",
      },
      MuflisTeenpattivirtual: {
        code: "67601",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Muflis Teenpatti (virtual)",
        name: "Muflis Teenpatti (virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/MuflisTeenpatti(virtual).jpg",
        redirectUrl: "/casino/aura-muflis-teenpatti-virtual",
      },
      Poker2020Virtual: {
        code: "67568",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Poker 20-20 (Virtual)",
        name: "Poker 20-20 (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/Poker20-20(Virtual).jpg",
        redirectUrl: "/casino/aura-poker-20-20-virtual",
      },
      Triovirtual: {
        code: "67611",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Trio (virtual)",
        name: "Trio (virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/Trio(virtual).jpg",
        redirectUrl: "/casino/aura-trio-virtual",
      },
      CasinoMeterVirtual: {
        code: "67576",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Casino Meter (Virtual)",
        name: "Casino Meter (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/CasinoMeter(Virtual).jpg",
        redirectUrl: "/casino/aura-casino-meter-virtual",
      },
      TeenpattiTestvirtual: {
        code: "67631",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Teenpatti Test (virtual)",
        name: "Teenpatti Test (virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/TeenpattiTest(virtual).jpg",
        redirectUrl: "/casino/aura-teenpatti-test-virtual",
      },
      TeenpattiOpenvirtual: {
        code: "67641",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Teenpatti Open (virtual)",
        name: "Teenpatti Open (virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/TeenpattiOpen(virtual).jpg",
        redirectUrl: "/casino/aura-teenpatti-open-virtual",
      },
      CasinoWarVirtual: {
        code: "67581",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Casino War (Virtual)",
        name: "Casino War (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/CasinoWar(Virtual).jpg",
        redirectUrl: "/casino/aura-casino-war-virtual",
      },
      BaccaratVirtual: {
        code: "92039",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Baccarat (Virtual)",
        name: "Baccarat (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/Baccarat(Virtual).jpg",
        redirectUrl: "/casino/aura-baccarat-virtual",
      },
      BollywoodCasinoVirtual: {
        code: "67571",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Bollywood Casino (Virtual)",
        name: "Bollywood Casino (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/BollywoodCasino(Virtual).jpg",
        redirectUrl: "/casino/aura-bollywood-casino-virtual",
      },
      QueenRaceVirtual: {
        code: "67621",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Queen Race (Virtual)",
        name: "Queen Race (Virtual)",
        providerName: "aura",
        imgUrl: "./images/aura/QueenRace.jpg",
        redirectUrl: "/casino/aura-queen-race-virtual",
      },
    },
    name: "Virtual Card Games",
    key: "virtualCardGames",
  },

  instantGames: {
    slide1: {
      Blastoff: {
        code: "67722",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Blastoff",
        name: "Blastoff",
        providerName: "aura",
        imgUrl: "./images/aura/Blastoff.jpg",
        redirectUrl: "/casino/aura-blastoff",
      },
      FortuneWheel: {
        code: "70001",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Fortune Wheel",
        name: "Fortune Wheel",
        providerName: "aura",
        imgUrl: "./images/aura/FortuneWheel.jpg",
        redirectUrl: "/casino/aura-fortune-wheel",
      },
      CoinToss: {
        code: "70003",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Coin Toss",
        name: "Coin Toss",
        providerName: "aura",
        imgUrl: "./images/aura/CoinToss.jpg",
        redirectUrl: "/casino/aura-coin-toss",
      },
      Limbo: {
        code: "70004",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Limbo",
        name: "Limbo",
        providerName: "aura",
        imgUrl: "./images/aura/Limbo.jpg",
        redirectUrl: "/casino/aura-limbo",
      },
      SlotGame: {
        code: "70005",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Slot Game",
        name: "Slot Game",
        providerName: "aura",
        imgUrl: "./images/aura/SlotGame.jpg",
        redirectUrl: "/casino/aura-slot-game",
      },
      RockPaperScissors: {
        code: "70006",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Rock Paper Scissors",
        name: "Rock Paper Scissors",
        providerName: "aura",
        imgUrl: "./images/aura/RockPaperScissors.jpg",
        redirectUrl: "/casino/aura-rock-paper-scissors",
      },
      Cricket: {
        code: "70008",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Cricket",
        name: "Cricket",
        providerName: "aura",
        imgUrl: "./images/aura/Cricket.jpg",
        redirectUrl: "/casino/aura-cricket",
      },
      ClassicDice: {
        code: "70009",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Classic Dice",
        name: "Classic Dice",
        providerName: "aura",
        imgUrl: "./images/aura/ClassicDice.jpg",
        redirectUrl: "/casino/aura-classic-dice",
      },
      Mines: {
        code: "70011",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Mines",
        name: "Mines",
        providerName: "aura",
        imgUrl: "./images/aura/Mines.jpg",
        redirectUrl: "/casino/aura-mines",
      },
      Aviator: {
        code: "67722",
        casino: "aura",
        provider: "aura",
        homeUrl: "http://localhost:3000/Casino",
        alt: "Aviator",
        name: "Aviator",
        providerName: "aura",
        imgUrl: "./images/aura/Aviator.jpg",
        redirectUrl: "/casino/aura-aviator",
      },
    },
    name: "Instant Games",
    key: "instantGames",
  },
};
