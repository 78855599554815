import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";
import BackIcon from "../../../../assets/images/back-icon.png";

import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import Facebook from "../../../../assets/images/facebook-512.svg";
import ContactUsImg from "../../../../assets/images/contactus-img2.png";

const ContactUsBefore = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <Container>
          <div className="headTitle mt-3">
            <div className="back-btn">
              <a href="javascript:void(0)" onClick={() => navigate(-1)}>
                <img src={BackIcon} alt="back" width={28} />
              </a>
            </div>

            <div className="title">
              <h3>Contact US</h3>
            </div>
          </div>

          <div className="contact_sec my-4">
            <Row className="align-items-center">
              <Col lg={6}>
                <ul>
                  <h5>Get In Touch With Us</h5>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "javascript:void(0)"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <div className="img-box">
                        <img src={Whatsapp} alt="whatsapp" />
                      </div>
                      <span>WhatsAap</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.TELEGRAM != ""
                          ? appDetails?.TELEGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                    >
                      <div className="img-box">
                        <img src={Telegram} alt="telegram" />
                      </div>
                      <span>Telegram</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.INSTATGRAM != ""
                          ? appDetails?.INSTATGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                    >
                      <div className="img-box">
                        <img src={Instagram} alt="instagram" />
                      </div>
                      <span>Instagram</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="panel social-icon watsapp"
                      href={
                        appDetails?.FACEBOOK != ""
                          ? appDetails?.FACEBOOK
                          : "javascript:void(0)"
                      }
                      target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                    >
                      <div className="img-box">
                        <img src={Facebook} alt="facebook}" />
                      </div>
                      <span>Facebook</span>
                    </a>
                  </li>
                </ul>
              </Col>
              <Col lg={6} className="d-none d-lg-block">
                <div className="contact-img">
                  <img src={ContactUsImg} alt="contact us" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </>
  );
};

export default ContactUsBefore;
