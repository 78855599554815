import React, { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { logoutUser } from "../redux/auth/actions";

import HomeIcon from "../assets/images/icons/leftbar/home.png";
import InplayIcon from "../assets/images/icons/leftbar/inplay.png";
import CricketIcon from "../assets/images/icons/leftbar/cricket.png";
import SoccerIcon from "../assets/images/icons/leftbar/soccer.png";
import TennisIcon from "../assets/images/icons/leftbar/tennis.png";
import aviator from "../assets/images/icons/leftbar/aviator.png";
import CasinoIcon from "../assets/images/icons/leftbar/ezugi.png";
import EvolutionIcon from "../assets/images/icons/leftbar/evolution.png";
import VivoIcon from "../assets/images/icons/leftbar/vivo.png";
import SupernowaIcon from "../assets/images/icons/leftbar/supernowa.png";
import xpgIcon from "../assets/images/icons/leftbar/xpg.png";
import WorldcasinoIcon from "../assets/images/icons/leftbar/worldcasino.png";
import SlotsIcon from "../assets/images/icons/leftbar/slots.png";

import horseracingIcon from "../assets/images/games-icon/horseracing-icon.png";
import SnookerIcon from "../assets/images/games-icon/snooker-icon.png";
import GreyhoundIcon from "../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";

import UserIcon from "../assets/images/user-icon.svg";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/telegram.svg";
import Instagram from "../assets/images/Instagram.svg";
import Facebook from "../assets/images/facebook-512.svg";
import { APP_CONST } from "../config/const";
import { isMobile } from "react-device-detect";

import WalletIcon from "../assets/images/icons/wallet-icon.png";
import ProfileIcon from "../assets/images/icons/menus/user.png";
import MyBetsIcon from "../assets/images/icons/menus/my-bets.png";
import BonusIcon from "../assets/images/icons/menus/bonus.png";
import ReferralIcon from "../assets/images/icons/menus/referral.png";
import AcStatementIcon from "../assets/images/icons/menus/statement.png";
import LogoutIcon from "../assets/images/icons/menus/logout.png";

const MobLeftbar = forwardRef((props, ref) => {
  console.log("ref", ref);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sportsData } = useSelector((state) => state.sports);
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails } = useSelector((state) => state.app);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const handleModalShow = (modalName) => {
    props.setShowAuthModals(true);
  };
  const handleModalClose = (modalName) => {
    props.setShowAuthModals(false);
  };

  const { user, balance, wallet } = useSelector((state) => state.auth);

  return (
    <div className="mobleftbarPage">
      <div className="leftbarSec" ref={ref}>
        {isAuth && (
          <div className="mb-3">
            <div className="d-flex profilemobsection">
              <div className="profile-img">
                <img src={UserIcon} alt="User Icon" />
              </div>
              <div className="user-info">
                <Link className="user_name" to="/profile">
                  {user?.mstruserid}
                </Link>
                <p>{user?.mobileno}</p>
              </div>
            </div>
            <div className="container upperbalnace">
              <div className="row">
                <div className="col-6">
                  <div className="balance mb-4">
                    <img src={WalletIcon} alt="wallet" />
                    Balance
                  </div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-4">
                    {" "}
                    INR {wallet?.balance}
                  </div>
                </div>

                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/withdraw");
                  }}
                >
                  <Button variant="" className="btn_withdraw w-100">
                    Withdraw
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/gatewaylist");
                  }}
                >
                  <Button variant="primary" className="btn_primary w-100">
                    Deposit
                  </Button>
                </div>

                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/bonus");
                  }}
                >
                  <Button variant="" className="btn_withdraw mt-2 w-100">
                    Bonus
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/mybets");
                  }}
                >
                  <Button variant="" className="btn_withdraw  mt-2 w-100">
                    My Bets
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        <ul>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sports");
              }}
            >
              <img src={HomeIcon} alt="Home Icon" />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/sportsbook/Inplay");
              }}
            >
              <img src={InplayIcon} alt="Inplay Icon" />
              <span>In-play</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/sportsbook/Cricket");
              }}
            >
              <img src={CricketIcon} alt="Cricket Icon" />
              <span>Cricket</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/sportsbook/Soccer");
              }}
            >
              <img src={SoccerIcon} alt="Soccer Icon" />
              <span>Soccer</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/sportsbook/Tennis");
              }}
            >
              <img src={TennisIcon} alt=" Icon" />
              <span>Tennis</span>
            </a>
          </li>
        </ul>

        <ul>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/casino/spribe/aviator");
              }}
            >
              <img src={aviator} alt="Inplay Icon" />
              <span>Aviator</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/casino/ezugi");
              }}
            >
              <img src={CasinoIcon} alt="Casino Icon" />
              <span>Ezugi</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/casino/evolution");
              }}
            >
              <img src={EvolutionIcon} alt="Casino Icon" />
              <span>Evolution</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/casino/vivo");
              }}
            >
              <img src={VivoIcon} alt="Casino Icon" />
              <span>Vivo</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/casino/supernowa");
              }}
            >
              <img src={SupernowaIcon} alt="Casino Icon" />
              <span>Supernowa</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/casino/xpg");
              }}
            >
              <img src={xpgIcon} alt="Casino Icon" />
              <span>XPG</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/casino/worldcasino");
              }}
            >
              <img src={WorldcasinoIcon} alt="Casino Icon" />
              <span>Worldcasino</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? isMobile
                    ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    : (window.location.href = "/sign-in")
                  : (window.location.href = "/slots");
              }}
            >
              <img src={SlotsIcon} alt="Casino Icon" />
              <span>Slots</span>
            </a>
          </li>
        </ul>

        <ul>
          <li>
            <a
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "javascript:void(0)"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              <img src={Whatsapp} alt="whatsapp" />
              <span>WhatsAap</span>
            </a>
          </li>
          <li>
            <a
              href={
                appDetails?.TELEGRAM != ""
                  ? appDetails?.TELEGRAM
                  : "javascript:void(0)"
              }
              target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
            >
              <img src={Telegram} alt="telegram" />
              <span>Telegram</span>
            </a>
          </li>
          <li>
            <a
              href={
                appDetails?.INSTATGRAM != ""
                  ? appDetails?.INSTATGRAM
                  : "javascript:void(0)"
              }
              target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
            >
              <img src={Instagram} alt="instagram" />
              <span>Instagram</span>
            </a>
          </li>
          <li>
            <a
              href={
                appDetails?.FACEBOOK != ""
                  ? appDetails?.FACEBOOK
                  : "javascript:void(0)"
              }
              target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
            >
              <img src={Facebook} alt="facebook}" />
              <span>Facebook</span>
            </a>
          </li>
        </ul>

        {isAuth ? (
          <ul className="mb-3">
            <li>
              <a href="/profile" className="img_size">
                <img src={ProfileIcon} alt="profile" />
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a href="/mybets" className="img_size">
                <img src={MyBetsIcon} alt="my bets" />
                <span>My Bets</span>
              </a>
            </li>
            <li>
              <a href="/bonus" className="img_size">
                <img src={BonusIcon} alt="bonus" />
                <span>Bonus</span>
              </a>
            </li>
            <li>
              <a href="/referral" className="img_size">
                <img src={ReferralIcon} alt="referral" />
                <span>Referral</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <img src={AcStatementIcon} alt="account statement" />
                <span>Account Statement</span>
              </a>
            </li>
            {isAuth && (
              <li onClick={() => dispatch(logoutUser())}>
                <div>
                  <a href="/">
                    <img src={LogoutIcon} alt="sign out" />
                    <span>Sign Out</span>
                  </a>
                </div>
              </li>
            )}
          </ul>
        ) : null}
      </div>
    </div>
  );
});

export default MobLeftbar;
