import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { registerUser } from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { useParams, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Modal } from "react-bootstrap";
import { APP_CONST } from "../../../config/const";
// import LoginLeftPart from "../../../assets/images/modal.gif";

const RegisterModal = ({ show, handleClose, handleModalShow }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [mobileDetails, setMobileDetails] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [referalCode, setReferalCode] = useState(location?.state?.referalCode);
  const [agentCode, setAgentCode] = useState(location?.state?.agentCode);
  const [isReffered, setIsReffered] = useState(false);
  const [cookies, setCookie] = useCookies(["refferal"]);
  const [agentCookies, setAgentCookie] = useCookies(["agentCode"]);
  const { appDetails } = useSelector((state) => state.app);
  useEffect(() => {
    getMobileDetails();
  }, []);
  //   useEffect(() => {
  //     if (referalCode) {
  //       setCookie("code", referalCode);
  //       setReferalCode(referalCode);
  //       setIsReffered(true);
  //     } else if (cookies?.code) {
  //       setReferalCode(cookies.code);
  //       setIsReffered(true);
  //     }
  //   }, [cookies, referalCode]);

  //   useEffect(() => {
  //     if (agentCode) {
  //       setAgentCookie("agent", agentCode);
  //     } else if (agentCookies?.agent) {
  //       setAgentCode(agentCookies?.agent);
  //     }
  //   }, [agentCookies, agentCode]);
  const getMobileDetails = () => {
    const details =
      localStorage.getItem("mobile_details") &&
      JSON.parse(localStorage.getItem("mobile_details"));
    if (details) {
      setMobileDetails(details);
    }
  };
  const validateData = (data = null) => {
    const dataToValidate = data ? data : formData;
    const { name, username, password, confirmPassword, email } = dataToValidate;
    const newErrors = {};
    if (!name || name === "") {
      newErrors.name = "Name cannot be empty";
    }
    if (!username || username === "") {
      newErrors.userName = "User name cannot be empty";
    }
    if (username && (username.length < 4 || username.length > 15)) {
      newErrors.userName = "Username must be 4 to 15 characters.";
    }
    if (!/^[A-Za-z0-9 ]+$/.test(username)) {
      newErrors.userName = "Special characters are not allowed in username.";
    }
    if (appDetails?.IS_EMAIL) {
      if (!email || email === "") {
        newErrors.email = "Email cannot be empty";
      }
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    if (password && (password.length < 8 || password.length > 20)) {
      newErrors.password = "Password must be 8 to 20 characters.";
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm password cannot be empty";
    } else if (password != confirmPassword) {
      newErrors.confirmPassword = "Password and Confirm password must be same";
    }
    setErrors(newErrors);
    return newErrors;
  };
  const handleSubmit = async () => {
    let errorsData = validateData();
    if (Object.keys(errorsData).length > 0) {
      //setErrors(errorsData);
    } else {
      let dataToSend = { ...formData };
      delete dataToSend.confirmPassword;
      if (agentCode) {
        // dataToSend.agent_code = agentCode;
      }
      dispatch(
        registerUser(
          {
            ...dataToSend,
            typeId: 3,
            parentId: 81026,
            mobile: mobileDetails?.mobile,
            country_code: mobileDetails.country_code,
          },
          navigate
        )
      );
      // checkIfExistingUserDetails(formData);
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
    validateData(tempFormData);
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas "}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL.REGISTER, "manual")}
        closeVariant="white"
      >
        <Modal.Title>Create an account!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <div className="login-section">
          <div className="Login-rightPart">
            <div className="form-steps">
              <h2 className="h2">Congratulation!</h2>
              <p>Your number have been verified. Please create your account.</p>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Full name*"
                    autoComplete="off"
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.name}
                  </Form.Control.Feedback>
                </Form.Group>
                {(appDetails?.IS_EMAIL && (
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email*"
                      autoComplete="off"
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                )) ||
                  null}
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="Choose Username*"
                    autoComplete="off"
                    onChange={handleChange}
                    isInvalid={!!errors.userName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.userName}
                  </Form.Control.Feedback>
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="Choose username*"
                    onChange={handleChange}
                    isInvalid={!!errors.userName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.userName}
                  </Form.Control.Feedback>
                </Form.Group> */}
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Choose Password*"
                    autoComplete="new-password"
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors?.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password*"
                    autoComplete="new-password"
                    onChange={handleChange}
                    isInvalid={!!errors.confirmPassword}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowConfirmPassword((p) => !p)}
                  >
                    {showConfirmPassword ? <IoIosEyeOff /> : <IoIosEye />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                {
                  // <Form.Group
                  //   className="mb-3 position-relative"
                  //   controlId="formBasicPassword"
                  // >
                  //   <Form.Control
                  //     type={"text"}
                  //     name="agent_code"
                  //     placeholder="Please enter agent code"
                  //     value={agentCode}
                  //     onChange={(e) => setAgentCode(e.target.value)}
                  //   />
                  // </Form.Group>
                }
              </Form>
              <Button
                className="btn_primary"
                variant=""
                type="submit"
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RegisterModal;
