import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Evolution from "../../../../../src/assets/images/Evolution-game-mob.png";
import Ezugi from "../../../../../src/assets/images/Ezugi-game-mob.jpg";
import World from "../../../../../src/assets/images/worldcasino-game-mob.png";

const Providers = () => {
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="provider-main ">
          <div class="all-games container">
            <h4>All Games</h4>
          </div>
          <div className="all-providers">
            <div class="all-providers-head">
              <div class="providers-img">
                <a href="/casino/ezugi">
                  <img src={Ezugi} alt="Live Dealer Icon" />
                </a>
              </div>
              <div class="providers-img">
                <a href="/casino/evolution">
                  <img src={Evolution} alt="Live Dealer Icon" />
                </a>
              </div>
              {/* <div class="providers-img">
                <a href="/casino/worldcasino">
                  <img src={World} alt="Live Dealer Icon" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Providers;
