import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
// import { slotsData, casinoGames } from "../../lib/data/slots.data";
import { auraGames } from "../../lib/data/auraGames.data";
import { popularTabs } from "../../lib/data/popularTabs.data";
import { casinoTabs } from "../../lib/data/casinoTabs.data";
import { SlotGames } from "../../lib/data/SlotGames.data";
import { gamesData } from "../../lib/data/searchGamesData";

const GamesSearchModal = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState(false);

  const totalGames = {
    ...auraGames,
    // ...auraGames.cardGames,
    // ...auraGames.virtualCardGames,
    // ...auraGames.instantGames,

    ...casinoTabs,
    // ...casinoTabs.baccarattab,
    // ...casinoTabs.Blackjacktab,
    // ...casinoTabs.pokertab,
    // ...casinoTabs.spribe,
    // ...casinoTabs.Supernowa,
    // ...casinoTabs.Vivo,
    // ...casinoTabs.TvShows,
    // ...casinoTabs.AllGames,

    ...popularTabs,

    ...SlotGames,
    // ...SlotGames.redtigertab,
  };

  useEffect(() => {
    return () => {
      setFilteredData(null);
    };
  }, []);

  const handleClose = () => {
    setFilteredData(null);
    setShow(false);
  };
  const handleSearch = (e) => {
    if (e) {
      setSearchText(true);
      let filteredValue = [];
      Object.values(totalGames).map((categories) => {
        Object.values(categories).map((item) => {
          if (typeof item !== "string") {
            Object.values(item).filter((game) => {
              if (game.name.toLowerCase().indexOf(e?.toLowerCase()) > -1) {
                filteredValue.push(game);
              }
            });
          }
        });
      });
      setFilteredData(filteredValue);
    } else {
      setSearchText(false);
    }
  };

  return (
    <div>
      <div className="searchGames" onClick={handleShow}>
        <BiSearch />
        <p>Search Games</p>
      </div>

      <div>
        <Modal show={show} onHide={handleClose} className="searchGames1">
          <Modal.Header closeButton>
            <Modal.Title>Search For Games</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3 search-icon">
              <Form.Control
                placeholder="Search"
                aria-label="Text input with dropdown button"
                type="search"
                onChange={(e) => handleSearch(e.target.value)}
                autoFocus
              />
            </InputGroup>

            <div className="GamesShow">
              {filteredData &&
                filteredData.map((item) => (
                  <div className="displayGames">
                    <a href={item.redirectUrl}>
                      <img src={item.imgUrl} />
                      <span>{item.name}</span>
                    </a>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default GamesSearchModal;
