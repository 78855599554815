import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HomeIcon from "../assets/images/icons/header/home.png";
import ExchangeIcon from "../assets/images/icons/header/exchange.png";
import EzugiIcon from "../assets/images/icons/header/casino.png";
import EvolutionIcon from "../assets/images/icons/header/evolution.png";
import AviatorIcon from "../assets/images/icons/header/aviator.png";

export default function BottomNav() {
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = [
    "home",
    "sportsbook",
    "evolution",
    "ezugi",
    "deposit",
    "aviator",
    "sports",
    "/",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <div className="footer-menu d-md-none position-fixed start-0 bottom-0 z-index-2 w-100 ">
        <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
          <li>
            <a
              className={activePage === "sportsbook" ? "active" : ""}
              href="/sportsbook/Inplay"
            >
              <img src={ExchangeIcon} alt="exchange" />
              <span>Exchange</span>
            </a>
          </li>
          <li>
            <a
              className={activePage === "ezugi" ? "active" : ""}
              href="/casino/ezugi"
            >
              <img src={EzugiIcon} alt="ezugi" />
              <span>Ezugi</span>
            </a>
          </li>
          <li>
            <a
              className={activePage === "sports" ? "active" : ""}
              href="/sports"
            >
              <img src={HomeIcon} alt="home" />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a
              className={activePage === "evolution" ? "active" : ""}
              href="/casino/evolution"
            >
              <img src={EvolutionIcon} alt="evolution" />
              <span>Evolution</span>
            </a>
          </li>
          <li>
            <a
              className={activePage === "aviator" ? "active" : ""}
              href="/casino/spribe/aviator"
            >
              <img src={AviatorIcon} alt="aviator" />
              <span>Aviator</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
