import React, { useEffect, useRef, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import { APP_CONST } from "../config/const";
import { CustomView, isMobile } from "react-device-detect";
import { useOutsideAlerter } from "../hooks/useOutsideClick";

import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";

import logo from "../assets/images/logo.png";
import ExchangeIcon from "../assets/images/icons/header/exchange.png";
import CasinoIcon from "../assets/images/icons/header/casino.png";
import EvolutionIcon from "../assets/images/icons/header/evolution.png";
import AviatorIcon from "../assets/images/icons/header/aviator.png";
import MenuIcon from "../assets/images/menu-icon.png";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import ModalWrapper from "./ModalWrapper";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  const catMenu = useRef(null);

  useOutsideAlerter(catMenu, setShow1);

  const handleShow1 = () => setShow1(!show1);

  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);

  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const handleModalShow = (modalName) => {
    // setShowAuthModals((p) => ({ ...p, [modalName]: true }));
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  const handleModalClose = (modalName) => {
    // setShowAuthModals((p) => ({ ...p, [modalName]: false }));
    setShowAuthModals(false);
  };

  return (
    <header className="header header_bfrlgn">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Button
                  variant="primary menu-btn"
                  onClick={handleShow1}
                  className="d-block d-md-none"
                  id="menuSidebarHandler"
                >
                  <img
                    src={MenuIcon}
                    alt="User Menu Icon"
                    width={25}
                    id="menuSidebarHandlerImg"
                  />
                </Button>

                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                    {/* <img src={logo} alt="Logo" /> */}
                  </a>
                </div>
              </div>

              <div className="header-left ms-4 d-none d-lg-block">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="d-none"
                />
                <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                  <Nav className="me-auto">
                    {/* {<Nav.Link href="#home">New Games</Nav.Link>} */}
                    <Nav.Link href="/sportsbook/Inplay">
                      <img src={ExchangeIcon} alt="exchange" />
                      <span>Exchange</span>
                    </Nav.Link>
                    <Nav.Link href="/Casino">
                      <img src={CasinoIcon} alt="casino" />
                      <span>Casino</span>
                    </Nav.Link>
                    <Nav.Link href="/casino/evolution">
                      <img src={EvolutionIcon} alt="evolution" />
                      <span>Evolution</span>
                    </Nav.Link>
                    <Nav.Link href="/casino/spribe/aviator">
                      <img
                        src={AviatorIcon}
                        alt="Aviator"
                        className="aviator"
                      />
                      <span>Aviator</span>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </div>

              <div className="headerRight">
                <ul className="d-none">
                  <li>
                    <a
                      className="tg"
                      href={
                        appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                      }
                      target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                    >
                      <img src={TelegramIcon} alt="Telegram Icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="wtp"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "#"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <img src={WhatsappIcon} alt="Whatsapp Icon" />
                    </a>
                  </li>
                  <li className="theme-btn">
                    <button type="button" className="btn" onClick={toggleClass}>
                      <img src={MoonLight} alt="moon" className="dark_theme" />
                      <img src={SunLight} alt="sun" className="light_theme" />
                    </button>
                  </li>
                </ul>
                <ul>
                  <li className="d-none d-sm-block">
                    <GamesSearchModal />
                  </li>
                </ul>
                <Button
                  variant="primary"
                  // onClick={() => {
                  //   navigate("/sign-in");
                  // }}
                  onClick={() => {
                    isMobile
                      ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                      : navigate("/sign-in");
                  }}
                  className="login_btn me-2"
                >
                  Enter
                  <span>18+</span>
                </Button>
                <Button
                  variant="primary"
                  // onClick={() => {
                  //   navigate("/mobile-number");
                  // }}

                  onClick={() => {
                    isMobile
                      ? handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)
                      : navigate("/mobile-number");
                  }}
                  className="signup_btn"
                >
                  REGISTER
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          setShowAuthModals={setShowAuthModals}
        />
      )}
      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      {show1 && (
        <MobLeftbar
          ref={catMenu}
          className="d-block d-md-none"
          setShowAuthModals={setShowAuthModals}
        />
      )}
    </header>
  );
};

export default Header;
