import React from "react";

export default function CasinoCard(data) {
  // console.log("data", data.data);
  let game = data && data.data;
  return (
    <>
      <li>
        <a href={game.redirectUrl} className="casino_games">
          <img src={game?.imgUrl} alt={game?.alt} />
          <span>{game?.name}</span>
        </a>
      </li>
    </>
  );
}
