import React, { useRef, useEffect } from "react";

export const useOutsideAlerter = (ref, manageState) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target?.id !== "menuSidebarHandler" &&
        event.target?.id !== "menuSidebarHandlerImg"
      ) {
        manageState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
