import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import CasinoCard from "../../../components/CasinoCard";
import { SlotGames } from "../../../../lib/data/SlotGames.data";
import BackIcon from "../../../../assets/images/back-icon.png";

const Slots = () => {
  const categoriesTab = [
    { name: "Netent", key: "netent" },
    { name: "RedTiger", key: "redTiger" },
  ];
  const [activeCategory, setActiveCategory] = useState("netent");
  const { isAuth } = useSelector((state) => state.auth);
  const [filteredData, setFilteredData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (event) => {
    const value = event.target.value;

    if (value) {
      setIsSearched(true);
      let filtered = [];
      Object.values(SlotGames)
        .filter((f) => f?.key === activeCategory)
        .map((slide) => {
          Object.values(slide)
            .filter((f) => typeof f !== "string")
            .map((game) => {
              let v = Object.values(game).filter(
                (f) => f?.name?.toLowerCase().indexOf(value.toLowerCase()) > -1
              );
              let clonedArray = [...filtered];
              clonedArray = [...filtered, ...v];
              filtered = clonedArray;
            });
        });
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
      setIsSearched(false);
    }
  };

  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="container">
          <div className="headTitle mt-3">
            <div className="back-btn">
              <a href="javascript:void(0)" onClick={() => navigate(-1)}>
                <img src={BackIcon} alt="back" width={28} />
              </a>
            </div>

            <div className="title">
              <h3>Slots</h3>
            </div>
          </div>

          <div className="casinoProvidersGames mt-4">
            <ul className="tabs">
              {categoriesTab.map((tab, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setFilteredData([]);
                    setIsSearched(false);
                    if (tab?.redirectUrl) {
                      navigate(tab?.redirectUrl);
                    } else {
                      setActiveCategory(tab.key);
                    }
                  }}
                >
                  <a
                    href="javascript:void(0)"
                    style={
                      activeCategory === tab.key
                        ? {
                            background: "#ff3131",
                            color: "#ffffff",
                          }
                        : {}
                    }
                  >
                    {tab.name}
                  </a>
                </li>
              ))}
            </ul>

            {!isSearched ? (
              <ul className="games">
                {Array.isArray(Object.values(SlotGames)) &&
                  Object.values(SlotGames)
                    .filter((f) => f?.key === activeCategory)
                    .map((slide) =>
                      Object.values(slide)
                        .filter((f) => typeof f !== "string")
                        .map((game) =>
                          Object.values(game).map((item, idx) => (
                            <CasinoCard key={"casinogame" + idx} data={item} />
                          ))
                        )
                    )}
              </ul>
            ) : (
              <ul className="games">
                {(filteredData.length &&
                  filteredData.map((game, idx) => (
                    <CasinoCard key={"casinogame" + idx} data={game} />
                  ))) ||
                  null}
              </ul>
            )}
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Slots;
