import { combineReducers } from "redux";
import auth from "./auth/reducer";
import sports from "./sports/reducer";
import app from "./app/reducer";
import user from "./user/reducer";
export default combineReducers({
  auth,
  sports,
  app,
  user,
});
