import { casinoTabs } from "./casinoTabs.data";

export const EvolutionGames = {
  ...casinoTabs.roulettetab,
  ...casinoTabs.baccarattab,
  ...casinoTabs.Blackjacktab,
  ...casinoTabs.sicbotab,
  ...casinoTabs.BetOnNumberTab,
  ...casinoTabs.AndarBahartab,
  ...casinoTabs.indianGames,
  ...casinoTabs.popularGames,
  ...casinoTabs.slotsgames,

  BaccaratB: {
    href: "/casino/evolution/BaccaratB",
    code: "1000013",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  BaccaratC: {
    href: "/casino/evolution/BaccaratC",
    code: "1000014",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  GoldenWealthBaccarat: {
    href: "/casino/evolution/GoldenWealthBaccarat",
    code: "1000355",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  LightningBaccarat: {
    href: "/casino/evolution/LightningBaccarat",
    code: "1000149",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  NoCommBaccarat: {
    href: "/casino/evolution/NoCommBaccarat",
    code: "1000018",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  NoCommSpeedBaccarat: {
    href: "/casino/evolution/NoCommSpeedBaccarat",
    code: "1000017",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SalonPriveBaccarat: {
    href: "/casino/evolution/SalonPriveBaccarat",
    code: "1000020",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratA: {
    href: "/casino/evolution/SpeedBaccaratA",
    code: "1000021",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratB: {
    href: "/casino/evolution/SpeedBaccaratB",
    code: "1000022",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratC: {
    href: "/casino/evolution/SpeedBaccaratC",
    code: "1000023",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratE: {
    href: "/casino/evolution/SpeedBaccaratE",
    code: "1000025",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  XtremeLightningRoulette: {
    href: "/casino/evolution/XtremeLightningRoulette",
    code: "1000120",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  InstantRoulette: {
    href: "/casino/evolution/InstantRoulette",
    code: "1000146",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  FootballStudioRoulette: {
    href: "/casino/evolution/FootballStudioRoulette",
    code: "1000112",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  AmericanRoulette: {
    href: "/casino/evolution/AmericanRoulette",
    code: "1000011",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  Roulette1: {
    href: "/casino/evolution/Roulette1",
    code: "vctlz20yfnmp1ylr",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DoubleballRoulette: {
    href: "/casino/evolution/DoubleballRoulette",
    code: "1000084",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DragonaraRoulette: {
    href: "/casino/evolution/DragonaraRoulette",
    code: "pnkk4iuchw7blb2p",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SalonPriveRoulette: {
    href: "/casino/evolution/SalonPriveRoulette",
    code: "1000102",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  BlackjackA: {
    href: "/casino/evolution/BlackjackA",
    code: "1000102",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  BlackjackB: {
    href: "/casino/evolution/BlackjackB",
    code: "1000031",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  BlackjackC: {
    href: "/casino/evolution/BlackjackC",
    code: "1000032",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  BlackjackVIP1: {
    href: "/casino/evolution/BlackjackVIP1",
    code: "1000048",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  Craps: {
    href: "/casino/evolution/Craps",
    code: "1000248",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  FreebetBlackjack: {
    href: "/casino/evolution/FreebetBlackjack",
    code: "1000066",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  InfiniteBlackjack: {
    href: "/casino/evolution/InfiniteBlackjack",
    code: "1000067",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  LightningBlackjack: {
    href: "/casino/evolution/LightningBlackjack",
    code: "1000358",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SalonPriveBlackjackA: {
    href: "/casino/evolution/SalonPriveBlackjackA",
    code: "1000068",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SalonPriveBlackjackB: {
    href: "/casino/evolution/SalonPriveBlackjackB",
    code: "1000069",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SalonPriveBlackjackC: {
    href: "/casino/evolution/SalonPriveBlackjackC",
    code: "1000070",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBlackjackD: {
    href: "/casino/evolution/SpeedBlackjackD",
    code: "1000033",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  CashorCrash: {
    href: "/casino/evolution/CashorCrash",
    code: "1000352",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  GonzoTreasureHunt: {
    href: "/casino/evolution/GonzoTreasureHunt",
    code: "1100022",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DreamCatcher: {
    href: "/casino/evolution/DreamCatcher",
    code: "1000077",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  Megaball: {
    href: "/casino/evolution/Megaball",
    code: "1000141",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
};
