export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const popularTabs = {
  popularetab: {
    slide: {
      TeenPattiVivoPplr: {
        redirectUrl: "/casino/vivo-teen-patti",
        code: "",
        casino: "vivo",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/Vivo/TeenPatti.jpg",
        alt: "vivo teen patti",
        name: "Vivo Teen Patti",
        providerName: "Vivo",
      },
      FrenchRoulette: {
        redirectUrl: "/casino/vivo-french-roulette",
        code: "",
        casino: "vivo",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/Vivo/FrenchRoulette.jpg",
        alt: "french roulette",
        name: "French Roulette",
        providerName: "Vivo",
      },
      GalaxyRoulette: {
        redirectUrl: "/casino/vivo-galaxy-roulette",
        code: "",
        casino: "vivo",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/Vivo/GalaxyRoulette.jpg",
        alt: "galaxy roulette",
        name: "Galaxy Roulette",
        providerName: "Vivo",
      },
      MacauBaccarat: {
        redirectUrl: "/casino/vivo-macau-baccarat",
        code: "",
        casino: "vivo",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/Vivo/MacauBaccarat.jpg",
        alt: "macau baccarat",
        name: "Macau Baccarat",
        providerName: "Vivo",
      },
      SpeedRoulettePplr: {
        redirectUrl: "/casino/ezugi/SpeedRoulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
        alt: "",
        name: "Speed Roulette",
        providerName: "Evolution",
      },
      LightningRoulettePplr: {
        redirectUrl: "/casino/ezugi/LightningRoulette",
        code: "1000092",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Lightiningroulette.jpg",
        alt: "",
        name: "Lightning Roulette",
        providerName: "Evolution",
      },
      CasinoMarinaRoulettePplr: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette",
        code: "321000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette.jpg",
        alt: "",
        name: "Casino Marina Roulette",
        providerName: "Ezugi",
      },
      BaccaratPplr: {
        redirectUrl: "/casino/ezugi/Baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat08.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
      },
      SpeedCricketBaccaratPplr: {
        redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
        code: "101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarat",
        providerName: "Ezugi",
      },
      LightBaccaratPplr: {
        redirectUrl: "/casino/ezugi/LightBaccarat",
        code: "1000149",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/lightning-baccarat.jpg",
        alt: "",
        name: "Lightning Baccarat",
        providerName: "Evolution",
      },
      BlackjackProPplr: {
        redirectUrl: "/casino/ezugi/BlackjackPro",
        code: "224",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-surrender-blackjack.jpg",
        alt: "",
        name: "VIP Surrender Blackjack",
        providerName: "Ezugi",
      },
      ItalianBlackjackPplr: {
        redirectUrl: "/casino/ezugi/ItalianBlackjack",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/italian-blackjack.jpg",
        alt: "",
        name: "Italian Blackjack",
        providerName: "Ezugi",
      },
      ClassicSpeedBlackjack1Pplr: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack1",
        code: "1000226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D.jpg",
        alt: "",
        name: " Classic Speed Blackjack 1",
        providerName: "Evolution",
      },
      BetonTeenPattiPplr: {
        redirectUrl: "/casino/ezugi/BetonTeenPatti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Betonteenpatti.jpg",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
      },
      TeenPatti3cardPplr: {
        redirectUrl: "/casino/ezugi/TeenPatti3card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Teenpatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
      },
      UltimatetexasholdemPplr: {
        redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/ultimatetaxasholden.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
      },
      CrazyTimePplr: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1000148",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CrazyTime.jpg",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
      },
      DreamCatcherPplr: {
        redirectUrl: "/casino/ezugi/DreamCatcher",
        code: "1000077",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/DreamCatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "Evolution",
      },
    },
  },
};
