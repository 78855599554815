import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Soon from "../../../../../src/assets/images/banner.jpg";
const Rummy = () => {
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="provider-main ">
          <div class="all-games container">
            <h4>Live Rummy</h4>
          </div>
          <div className="all-providers">
            <div class="all-providers-head">
              <div class="coming-soon">
                <a href="/casino/ezugi">
                  <img src={Soon} alt="Live Dealer Icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Rummy;
