import React from "react";
import { QRCodeCanvas } from "qrcode.react";

export default function QRCodeCard({ text }) {
  return (
    <div className="mt-4 w-full max-w-[12rem]">
      <div className="bg-zinc-50 rounded-lg text-center">
        <QRCodeCanvas
          size={150}
          id="upi-qrcode"
          renderAs="canvas"
          value={text}
          bgColor={"#fafafa"}
        />
      </div>
    </div>
  );
}
