import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { loginUser, resetPassword } from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { APP_CONST } from "../../../config/const";

import { useNavigate } from "react-router-dom";

import { Toaster } from "../../../utils/toaster";

const ResetPassword = ({ show, handleClose, handleModalShow }) => {
  const { appDetails } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtpValue] = useState("");
  const [password, setPassword] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = () => {
    if (otp == "" || password == "") {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "All fields are mandatory");
    } else {
      const payload = {
        otp,
        password,
      };

      resetPassword(payload)
        .then((res) => {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Password reset success");
          // navigate("/sign-in");
          handleClose();

          handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
        })
        .catch((err) => {
          Toaster(
            APP_CONST.TOAST_TYPE.ERROR,
            err.response.data.message || "Request failed"
          );
        });
    }
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header closeButton onClick={handleClose} closeVariant="white">
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          <div className="ModalSection">
            <div className="Modal-right-part">
              <div className="Login-rightPart">
                <div className="form-steps">
                  <h2 className="h2">Verify!</h2>
                  <p>Please enter OTP you received on your mobile number</p>
                  <Form>
                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        type="password"
                        placeholder="Enter OTP*"
                        onChange={(e) => setOtpValue(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter new password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="icon"
                        onClick={() => setShowPassword((s) => !s)}
                      >
                        {showPassword ? <IoIosEyeOff /> : <IoIosEye />}
                      </span>
                    </Form.Group>
                  </Form>

                  <Button
                    className="btn_primary"
                    variant=""
                    onClick={handleSubmit}
                    disabled={isVerifying}
                  >
                    {!isVerifying ? "SUBMIT" : "SUBMITTING"}
                  </Button>

                  <div className="sign-up mt-4">
                    Already a member?
                    <br />
                    <a href="/sign-in">Sign In here</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ResetPassword;
