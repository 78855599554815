import { casinoTabs } from "./casinoTabs.data";

export const EzugiGames = {
  ...casinoTabs.roulettetab,
  ...casinoTabs.AndarBahartab,
  ...casinoTabs.TeenPattitab,
  ...casinoTabs.Blackjacktab,
  ...casinoTabs.thirtytwoCardtab,
  ...casinoTabs.sevenLuckytab,
  ...casinoTabs.sicbotab,
  ...casinoTabs.BetOnNumberTab,
  ...casinoTabs.baccarattab,
  ...casinoTabs.indianGames,
  ...casinoTabs.popularGames,
  ...casinoTabs.slotsgames,
  ...casinoTabs.Slots,
  ...casinoTabs.netent,
  ...casinoTabs.RedTiger,

  ...casinoTabs.roulettetab.slide1,
  ...casinoTabs.roulettetab.slide2,
  // ...casinoTabs.roulettetab.slide3,
  ...casinoTabs.baccarattab.slide1,
  ...casinoTabs.baccarattab.slide2,
  // ...casinoTabs.baccarattab.slide3,
  ...casinoTabs.Blackjacktab.slide1,
  ...casinoTabs.Blackjacktab.slide2,
  // ...casinoTabs.Blackjacktab.slide3,
  ...casinoTabs.spribe.slide1,
  ...casinoTabs.pokertab.slide1,

  ...casinoTabs.TvShows.slide1,
  // ...slotsData.TVShows.slide1,

  ...casinoTabs.AllGames.slide1,
  ...casinoTabs.AllGames.slide2,
  ...casinoTabs.AllGames.slide3,
  ...casinoTabs.AllGames.slide4,
  ...casinoTabs.AllGames.slide5,
  ...casinoTabs.AllGames.slide6,
  ...casinoTabs.AllGames.slide7,
  ...casinoTabs.AllGames.slide8,
  ...casinoTabs.AllGames.slide9,

  OTTAndarbahar: {
    href: "/casino/ezugi/OTTAndarbahar",
    code: "328000",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  Andarbahar: {
    href: "/casino/ezugi/Andarbahar",
    code: "228000",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  UltimateAndarbahar: {
    href: "/casino/ezugi/UltimateAndarbahar",
    code: "228100",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  Baccarat: {
    href: "/casino/ezugi/Baccarat",
    code: "100",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  FortuneBaccarat: {
    href: "/casino/ezugi/FortuneBaccarat",
    code: "102",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedCricketBaccarat: {
    href: "/casino/ezugi/SpeedCricketBaccarat",
    code: "101",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  NoCommissionBaccarat: {
    href: "/casino/ezugi/NoCommissionBaccarat",
    code: "170",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  FiestaBaccarat: {
    href: "/casino/ezugi/FiestaBaccarat",
    code: "43100",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  CasinoMariniaBaccarat1: {
    href: "/casino/ezugi/CasinoMariniaBaccarat1",
    code: "32100",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  CasinoMariniaBaccarat2: {
    href: "/casino/ezugi/CasinoMariniaBaccarat2",
    code: "32101",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  CasinoMariniaBaccarat3: {
    href: "/casino/ezugi/CasinoMariniaBaccarat3",
    code: "32102",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  CasinoMariniaBaccarat4: {
    href: "/casino/ezugi/CasinoMariniaBaccarat4",
    code: "32103",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  SpeedRoulette: {
    href: "/casino/ezugi/SpeedRoulette",
    code: "221000",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  NamasteRoulette: {
    href: "/casino/ezugi/NamasteRoulette",
    code: "221005",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  AutoRoulette: {
    href: "/casino/ezugi/AutoRoulette",
    code: "5001",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedAutoRoulette: {
    href: "/casino/ezugi/SpeedAutoRoulette",
    code: "221002",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DiamondRoulette: {
    href: "/casino/ezugi/DiamondRoulette",
    code: "221003",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  PrestigeRoulette: {
    href: "/casino/ezugi/PrestigeRoulette",
    code: "221004",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  ItalianRoulette: {
    href: "/casino/ezugi/ItalianRoulette",
    code: "1000",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  RussianRoulette: {
    href: "/casino/ezugi/RussianRoulette",
    code: "426",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  TurkishRoulette: {
    href: "/casino/ezugi/TurkishRoulette",
    code: "501000",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  OracleRoulette: {
    href: "/casino/ezugi/OracleRoulette",
    code: "611001",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  Blackjack1: {
    href: "/casino/ezugi/Blackjack1",
    code: "201",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  Blackjack2: {
    href: "/casino/ezugi/Blackjack2",
    code: "202",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  BlackjackGold6: {
    href: "/casino/ezugi/BlackjackGold6",
    code: "223",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  BlackjackGold4: {
    href: "/casino/ezugi/BlackjackGold4",
    code: "4",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  BlackjackPlatinum1: {
    href: "/casino/ezugi/BlackjackPlatinum1",
    code: "5",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  ItalianBlackjack: {
    href: "/casino/ezugi/ItalianBlackjack",
    code: "226",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  TurkishBlackjack: {
    href: "/casino/ezugi/TurkishBlackjack",
    code: "501",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  UnlimitedBlackjack: {
    href: "/casino/ezugi/UnlimitedBlackjack",
    code: "51",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  VIPBlackjack2: {
    href: "/casino/ezugi/VIPBlackjack2",
    code: "222",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  VIPDiamomdBlackjack: {
    href: "/casino/ezugi/VIPDiamomdBlackjack",
    code: "1000030",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  ABSupernowa: {
    href: "/casino/ezugi/ABSupernowa",
    code: "AB",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  ABClassicSupernowa: {
    href: "/casino/ezugi/ABClassicSupernowa",
    code: "ABC",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  GoaABSupernowa: {
    href: "/casino/ezugi/GoaABSupernowa",
    code: "AB2",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  RNGAB2020Supernowa: {
    href: "/casino/ezugi/RNGAB2020Supernowa",
    code: "VAB20",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  BaccaratSupernowa: {
    href: "/casino/ezugi/BaccaratSupernowa",
    code: "BAC",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  RNGBaccaratSupernowa: {
    href: "/casino/ezugi/RNGBaccaratSupernowa",
    code: "VBAC",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  RNGBaccarat2020Supernowa: {
    href: "/casino/ezugi/RNGBaccarat2020Supernowa",
    code: "VBAC20",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  RouletteSupernowa: {
    href: "/casino/ezugi/RouletteSupernowa",
    code: "RT",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
};
